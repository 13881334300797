import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCompanyDepartment } from "../app/modules/companyInfo/core/_request"

type DepartmentState = {
    status: 'loading' | 'idle'
    error: string | null
    departments: string[]
}
const initialState: DepartmentState = {
    error: null,
    departments: [],
    status: 'idle',
}

export const getCompanyDepartments = createAsyncThunk('/department', async (_, thunkApi) => {
    try {
        const { data: result } = await getCompanyDepartment()
        return result.data
    } catch (error: any) {
        const message = error.message
        return thunkApi.rejectWithValue(message)
    }
})

const departmentSlice = createSlice({
    name: 'Department',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyDepartments.pending, (state) => {
            state.status = 'loading'
            state.error = null
        })
        builder.addCase(getCompanyDepartments.fulfilled, (state, { payload }) => {
            state.departments = []
            state.departments.push(...payload.companyDepartments)
            state.status = 'idle'
        })
        builder.addCase(getCompanyDepartments.rejected, (state, { payload }) => {
            if (payload) {
                // state.error = payload.message;
                console.log(state.error)
            }
            state.status = 'idle'
        })
    },
})

export default departmentSlice.reducer