import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {Permission} from '../../employeeCenter/core/_models'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  accessPermissions: (titlePermission: string) => boolean
  accessPermissionBytype: (
    titlePermission: string,
    type: 'isRead' | 'isWrite' | 'isUpdate' | 'isDelete'
  ) => boolean
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  accessPermissions: () => {
    return false
  },
  accessPermissionBytype: () => {
    return false
  },
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  const accessPermissions = (titlePermission: string) => {
    const permissions = currentUser?.role.permissions
    const permission: Permission = permissions![titlePermission as keyof typeof permissions]
    for (let nestedKey in permission) {
      if (Object.prototype.hasOwnProperty.call(permission, nestedKey)) {
        const checked: boolean = permission[nestedKey as keyof typeof permission]
        if (checked) {
          return true
        }
      }
    }
    return false
  }
  const accessPermissionBytype = (titlePermission: string, type: string) => {
    const permissions = currentUser?.role.permissions
    const permission: Permission = permissions![titlePermission as keyof typeof permissions]
    for (let nestedKey in permission) {
      if (Object.prototype.hasOwnProperty.call(permission, nestedKey)) {
        const checked: boolean = permission[nestedKey as keyof typeof permission]
        if (nestedKey === type && checked) {
          return true
        }
      }
    }
    return false
  }
  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        accessPermissions,
        accessPermissionBytype,
        currentUser,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (token: string) => {
      try {
        if (!didRequest.current) {
          const {data: result} = await getUserByToken(token)
          if (result.status === 'SUCCESS') {
            setCurrentUser(result.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
