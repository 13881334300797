import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { axiosInstance, AxiosResponse } from '../app/modules/auth'
export type checkbox = {
  _id: string
  name: string
  categoryName: string
  status?: string
}
interface CheckBoxesByCategory {
  _id: string
  checkBoxes: checkbox[]
}
type CheckBoxesState = {
  status: 'loading' | 'idle'
  error: string | null
  checkBoxes: CheckBoxesByCategory[]
}
const initialState: CheckBoxesState = {
  error: null,
  checkBoxes: [],
  status: 'idle',
}


export const getOptionsByCategory = createAsyncThunk(
  '/riskAssessmentCheckboxes',
  async (category: string, thunkApi) => {
    try {
      const { data: result } = await axiosInstance.get<AxiosResponse>(
        `formSetting/riskAssessment/getOptionsByCategory/${category}`
      )
      return result.data
    } catch (error: any) {
      const message = error.message
      return thunkApi.rejectWithValue(message)
    }
  }
)

const riskAssesmmentSlice = createSlice({
  name: 'riskAssesmmentSlice',
  initialState,
  reducers: {

    addNewOption: (state, action: PayloadAction<checkbox>) => {
      const indexCategory = state.checkBoxes.findIndex((item) => item._id === action.payload.categoryName)
      if (indexCategory === -1) {
        state.checkBoxes.push({ _id: action.payload.categoryName, checkBoxes: [action.payload] })
        return
      }
      const categIndex = state.checkBoxes.findIndex((category) => category._id === action.payload.categoryName)
      state.checkBoxes[categIndex].checkBoxes.unshift(action.payload)
    },
    updateOptionState: (state, action: PayloadAction<checkbox>) => {
      const categIndex = state.checkBoxes.findIndex((item) => (item._id === action.payload.categoryName))
      state.checkBoxes[categIndex].checkBoxes.map((checkbox, index) => {
        if (checkbox._id === action.payload._id) {
          state.checkBoxes[categIndex].checkBoxes[index] = action.payload
        }
        return checkbox
      })
    },
    deleteOptionState: (state, action: PayloadAction<{ categoryID: string; optionId: string }>) => {
      const categIndex = state.checkBoxes.findIndex((category) => category._id === action.payload.categoryID)
      state.checkBoxes[categIndex].checkBoxes.splice(
        state.checkBoxes[categIndex].checkBoxes.findIndex((option) => option._id === action.payload.optionId),
        1
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOptionsByCategory.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(getOptionsByCategory.fulfilled, (state, { payload }) => {
      state.checkBoxes = []
      state.checkBoxes.push(...payload)
      state.status = 'idle'
    })
    builder.addCase(getOptionsByCategory.rejected, (state, { payload }) => {
      if (payload) {
        // state.error = payload.message;
        console.log(state.error)
      }
      state.status = 'idle'
    })
  },
})
export const { addNewOption, deleteOptionState, updateOptionState } = riskAssesmmentSlice.actions
export default riskAssesmmentSlice.reducer
