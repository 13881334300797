import * as Yup from 'yup'

export interface CreateAccount {
  accountType: string

  teamSize: string
  companyName: string
  companyABN: string
  companyIndustry: {
    option: string
    other: string
  }


  companyEmail: string
  companyPhone: string
  companyFax: string
  companyDescription: string


  personFirstName: string,
  personLastName: string,
  personEmail: string,
  personPhone: string,
  password:string,

  companyPlan: string
}

const createAccountSchemas = [
  Yup.object({
    accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    teamSize: Yup.string().required().label('Company Size'),
    companyName: Yup.string().required().label('Company Name'),
    companyABN: Yup.string().required().label('Company ABN'),
    companyIndustry: Yup.object({
      option: Yup.string().required('Company industry is required'),
      other: Yup.string()
      .when(['option'], (option, schema) => {
          if (option === 'Other') {
            return schema.required('Company industry is required')
          }
          return schema.optional()
        }),

    }),
  }),
  Yup.object({
    companyEmail: Yup.string().required().label('Company Email'),
    companyPhone: Yup.string().required().label('Company Phone'),
    companyDescription: Yup.string().required().label('Company Description'),
  }),

  Yup.object({
    personFirstName: Yup.string().required().label('First Name'),
    personLastName: Yup.string().required().label('First Name'),
    personEmail: Yup.string().required().label('Company Email'),
    personPhone: Yup.string().required().label('Company Phone'),
    password: Yup.string().required().label('Password'),
  }),

  Yup.object({
    companyPlan: Yup.string().required().label('Company Plan'),
  }),
]

const inits: CreateAccount = {
  accountType: 'personal',

  teamSize: '50+',
  companyName: '',
  companyIndustry: {
    option: '',
    other: ''
  },
  companyABN: '',

  companyEmail: 'corp@support.com',
  companyPhone: '',
  companyFax: '',
  companyDescription: '',

  personFirstName: '',
  personLastName: '',
  personEmail: '',
  personPhone: '',
  password:'',

  companyPlan: 'Business',
}

export { createAccountSchemas, inits }
