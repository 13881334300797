// http://localhost:3148/api/v1/company/get/63ee1d472e24da960eaed36c

import { axiosInstance, AxiosResponse } from "../../auth";
import { CompanyData } from "./_models";

export function getCompayDetails(id: string) {
    return axiosInstance.get<AxiosResponse>(`/company/info/get/${id}`)
}
export function updateCompany(id: string, body: CompanyData) {
    return axiosInstance.put<AxiosResponse>(`/company/info/update/${id}`, body)
}
//************************************ Department *********************************** */
export function getAllDepartments(departmentName: string, page: number, limit: number) {
    return axiosInstance.get<AxiosResponse>(`company/department/getAll?page=${page}&limit=${limit}&searchByDepartmentName=${departmentName}`)
}
export const addDepartment = (formData: any) => {
    return axiosInstance.post<AxiosResponse>(`company/department/add`, formData)
}
export const updateDepartment = (departmentName: string, formData: any) => {
    return axiosInstance.put<AxiosResponse>(`company/department/update/${departmentName}`, formData)
}
export const deleteDepartment = (customerId: string) => {
    return axiosInstance.delete<AxiosResponse>(`company/department/delete/${customerId}`)
}
//************************************ Position **********************/
export function getCompanyDepartment() {
    return axiosInstance.get<AxiosResponse>(`/company/info/departments`)
}
export function getCompanyPositions() {
    return axiosInstance.get<AxiosResponse>(`/company/info/positions`)
}
export function getCompanyUsers(query: any) {
    return axiosInstance.get<AxiosResponse>(`/company/info/users${query}`)
}