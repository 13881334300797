/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {CorrectiveActionsDropdownFilter} from '../../content/dropdown/CorrectiveActionsDropdownFilter'

type Props = {
  className: string
  tableLable?: string
  tableData: Array<{
    item: string
    action: string
    personResponible: string
    reportTo: string
    dueDate: string
    status: boolean
  }>
}

// CorrectiveActionsDropdownFilter
const CorrectiveActions: React.FC<Props> = ({className, tableData, tableLable}) => {
  const [tableBodyData, setTableBodyData] = useState(tableData)
  function handleChangeStatus(nameItem: string) {
    const newArr = [...tableBodyData]
    newArr.find((element) => {
      if (element.item === nameItem) {
        return (element.status = !element.status)
      }
    })
    setTableBodyData(newArr)
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{tableLable}</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <CorrectiveActionsDropdownFilter />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-150px'>ITEM</th>
                <th className='min-w-140px'>ACTION</th>
                <th className='min-w-120px'>PERSON RESPONSIBLE</th>
                <th className='min-w-120px'>REPORT TO </th>
                <th className='min-w-120px'>DUE DATE</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-100px text-end pe-4'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tableBodyData.map((element, index) => (
                <tr key={index}>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{element.item}</td>
                  <td className='text-muted fw-bold  mb-1 fs-6'>{element.action}</td>
                  <td className='text-muted fw-bold  mb-1 fs-6'>{element.personResponible}</td>
                  <td className='text-muted fw-bold   mb-1 fs-6'>{element.reportTo}</td>
                  <td className='text-dark fw-bold  fs-6'>{element.dueDate}</td>
                  <td>
                    <span
                      className={`badge ${
                        element.status === true ? 'badge-light-success' : 'badge-light-danger'
                      }`}
                    >
                      {element.status === true ? 'Completed' : 'Not Completed'}
                    </span>
                  </td>
                  <td className='text-end'>
                    <button
                      className='btn me-3 btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={() => {handleChangeStatus(element.item)}}
                    >
                      <KTSVG
                        path={
                          element.status === true
                            ? '/media/icons/duotune/arrows/arr015.svg'
                            : '/media/icons/duotune/arrows/arr016.svg'
                        }
                        className='svg-icon-3'
                      />
                    </button>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <div className='card-footer d-flex flex-stack flex-wrap'>
        <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 10 entries</div>

        <ul className='pagination'>
          <li className='page-item previous'>
            <Link to='' className='page-link'>
              <i className='previous'></i>
            </Link>
          </li>

          <li className='page-item active'>
            <Link to='' className='page-link'>
              1
            </Link>
          </li>

          {/* <li className='page-item'>
            <Link to='' className='page-link'>
              2
            </Link>
          </li> */}

          <li className='page-item next'>
            <Link to='' className='page-link'>
              <i className='next'></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export {CorrectiveActions}
