import { PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {FC, useEffect} from 'react'

type props = {
  message: string
  clientSecret: string
  setStripe: (stripe: any) => void
  setElement: (element: any) => void
}
const StripeForm: FC<props> = ({clientSecret, setStripe, setElement, message}) => {
  const stripe: any = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (!stripe) {
      return
    }
    setStripe(stripe)
    setElement(elements)
    if (!clientSecret) {
      return
    }

    // stripe.retrievePaymentIntent(clientSecret).then((result: any) => {
    //   console.log(result);

    //     switch (result.paymentIntent.status) {
    //       case "succeeded":
    //         setMessage("Payment succeeded!");
    //         break;
    //       case "processing":
    //         setMessage("Your payment is processing.");
    //         break;
    //       case "requires_payment_method":
    //         setMessage("Your payment was not successful, please try again.");
    //         break;
    //       default:
    //         setMessage("Something went wrong.");
    //         break;
    //     }
    // })
  }, [stripe])

  return (
    <div className='container mx-auto w-75'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Billing Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='text-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <PaymentElement
        id='payment-element'
        options={{
          layout: 'tabs',
        }}
      />
      {message && (
        <div className='fv-plugins-message-container'>
          <div className='float-left fv-help-block'>{message}</div>
        </div>
      )}
    </div>
  )
}
export {StripeForm}
