export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname
export function dataURLtoFile(dataurl: any, filename: string) {

    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    const file  = new File([u8arr], filename, { type: mime });
    
    return file
}
export function formatDate(date: string ) {
    return new Date(date).toLocaleString('en-US', {timeZone: "Australia/Sydney"})
  }