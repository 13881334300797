/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import { Payment } from './components/Payment'
import {PaymentLayout} from './PaymentLayout'

const PaymentStatusPage = () => (
  <Routes>
    <Route element={<PaymentLayout />}>
      <Route path='status' element={<Payment />} />
      <Route index element={<Payment />} />
    </Route>
  </Routes>
)

export default PaymentStatusPage
