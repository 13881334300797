import {FC} from 'react'
import {Radar} from 'react-chartjs-2'

type Props = {className: string}

const data = {
  labels: ['Hernia', 'Other', 'Cuts', 'Bum', 'Broken bone' ,'liness'],
  datasets: [
    {
      label: 'Injuries',
      backgroundColor: 'rgba(34, 202, 236, .2)',
      borderColor: 'rgba(34, 202, 236, 1)',
      pointBackgroundColor: 'rgba(34, 202, 236, 1)',
      poingBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(34, 202, 236, 1)',
      data: [50,80 ,0,110,90,120],
    },
  ],
}

const TypesOfInjuries: FC<Props> = ({className}) => {
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='card-label fw-bold text-dark'>Types Of Injuries</span>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Lorem Ipsum</span>
        </div>
      </div>

      <div className='card-body pt-12 pb-12 d-flex justify-content-around flex-wrap align-items-center'>
        <div className='col-6 d-flex flex-center pt-2'>
          <Radar data={data} />
        </div>
      </div>
    </div>
  )
}
export {TypesOfInjuries}
