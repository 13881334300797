import {useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {
  createAccountSchemas,
  CreateAccount,
  inits,
} from '../../wizards/components/CreateAccountWizardHelper'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import { register } from '../core/_requests'
import { ContactDetails } from './steps/ContactPerson'

const Registration = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<CreateAccount>(inits)
  const [buttonLabel, setButtonLabel] = useState('Continue')
  const [stripe, setStripe] = useState<any>(null)
  const [elements, setElement] = useState<any>(null)
  const [clientSecret, setClientSecret] = useState('')
  const [message, setMessage] = useState<string>('')

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
    setButtonLabel('Continue')

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  async function sendconfirmPayment() {
    if (!stepper.current) {
      return
    }
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_PUBLIC_URL}/payment/status`,
      },
    })
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occurred.')
    }
  }
  
  

  const submitStep = async (values: CreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current?.currentStepIndex >= stepper.current.totatStepsNumber -1) {
      setButtonLabel('Submit')
    }else{
      setButtonLabel('Continue')
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      
      if (stepper.current.currentStepIndex === 5) {
        // send Request for create customer and subscription
        const  {data:result} = await register(values)
        console.log(result);
        setClientSecret(result.data.clientSecret)
      }
      stepper.current.goNext()
    } else {
      //after enter payment form confirm payment
      sendconfirmPayment()
      actions.resetForm()
    }
  }

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid vh-100'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div
        className='d-flex flex-column justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-350px w-xxl-400px'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        <div className='d-flex flex-center py-10 py-lg-10 mt-lg-20'>
          <img
            alt=''
            className='h-100px'
            src={`${toAbsoluteUrl('/media/logos/ez-main-logo-white.png')}`}
          />
        </div>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title fs-2'>Account Type</h3>
                  <div className='stepper-desc fw-normal'>Setup Your Account Details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title fs-2'>Account Settings</h3>
                  <div className='stepper-desc fw-normal'>Setup your account settings</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title fs-2'>Business Details</h3>
                  <div className='stepper-desc fw-normal'>Setup your business details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}
            
            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title fs-2'>Contact Details</h3>
                  <div className='stepper-desc fw-normal'>Setup your Contact details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}
            {/* begin::Step 5*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title fs-2'>Price Details</h3>
                  <div className='stepper-desc fw-normal'>Choose your price Plan</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 5*/}
            {/* begin::Step 6*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>6</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title fs-2'>Completed</h3>
                  <div className='stepper-desc fw-normal'>Your account is created</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 6*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body'>
        <Formik
          validationSchema={currentSchema}
          initialValues={initValues}
          onSubmit={submitStep}
          validateOnBlur={false}
        >
          {({setStatus,status}) => (
            <Form className='py-20 w-100  px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2  setStatus={setStatus} status={status}/>
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 />
              </div>

              <div data-kt-stepper-element='content'>
                <ContactDetails setStatus={setStatus} status={status}/>
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 message={message} clientSecret={clientSecret} setStripe={setStripe} setElement={setElement} />
              </div>

              <div className='mx-auto w-75 d-flex justify-content-between flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>

                <div>
                  <button disabled={status && Object.keys(status).length !== 0} type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {buttonLabel}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {Registration}
