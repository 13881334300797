import {FC, useCallback, useEffect} from 'react'
import {Field, ErrorMessage, useField} from 'formik'
import ReactSelect from '../../../../../_metronic/helpers/components/ReactSelect'
import {industryOptions} from '../../../companyInfo/core/_models'
import {validCompanyAccount} from '../../core/_requests'

type props = {
  setStatus: (error: any) => void
  status: any
}
const Step2: FC<props> = ({setStatus, status}) => {
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: 'rgb(238,243,247)',
      fonstSize: '1.1rem',
      fontWeight: '500',
      lineHeight: '1.5',
      minHeight: '45.88px',
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
    }),
  }
  const [, metaCompanyABN] = useField('companyABN')
  const [, meta, helpers] = useField('companyIndustry')
  let otherOption = meta.value.option === 'Other'

  useEffect(() => {
    if (!otherOption) {
      helpers.setValue({...meta.value, other: ''})
    }
  }, [otherOption])

  const validComapny = useCallback(async () => {
    try {
      if (metaCompanyABN.value) {
        await validCompanyAccount(metaCompanyABN.value)
      }
      setStatus({})
    } catch (error: any) {
      if (metaCompanyABN.value) {
        setStatus({personEmail: error.response.data.message})
      }
    }
  }, [metaCompanyABN.value, setStatus])

  useEffect(() => {
    const timer = setTimeout(() => {
      validComapny()
    }, 700)
    return () => clearTimeout(timer)
  }, [validComapny])

  return (
    <div className='container mx-auto w-75'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Account Info</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Specify Team Size
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value='1'
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>1-1</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value='10'
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>2-10</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value='50'
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bolder fs-3'>10-50</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='teamSize'
              value='50+'
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bolder fs-3'>50+</span>
            </label>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='teamSize' />
          </div>
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='fs-6 fw-bold form-label required'>Company Name</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='companyName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName'>{(msg) => <div>{msg}</div>}</ErrorMessage>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='fs-6 fw-bold form-label required'>Company ABN</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='companyABN'
        />

        <div className='text-danger mt-2'>
          <ErrorMessage name='companyABN'>{(msg) => <div>{msg}</div>}</ErrorMessage>
          {status?.personEmail}
        </div>
      </div>

      <div className='row d-flex align-items-start'>
        <div className={`mb-10 ${otherOption ? 'col-md-6' : 'col-md-12'}`}>
          <label className='fs-6 fw-bold form-label required'>Company Industry</label>

          <Field
            name='companyIndustry.option'
            id='companyIndustry.option'
            placeholder=''
            isMulti={false}
            styles={customStyles}
            component={ReactSelect}
            options={industryOptions}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='companyIndustry.option'>{(msg) => <div>{msg}</div>}</ErrorMessage>
          </div>
        </div>
        {otherOption && (
          <div className='mb-10 col-md-6'>
            <label className='fs-6 fw-bold form-label required'>Other</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='companyIndustry.other'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='companyIndustry.other'>{(msg) => <div>{msg}</div>}</ErrorMessage>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {Step2}
