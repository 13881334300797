import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCompanyPositions } from "../app/modules/companyInfo/core/_request"

type DepartmentState = {
    status: 'loading' | 'idle'
    error: string | null
    positions: string[]
}
const initialState: DepartmentState = {
    error: null,
    positions: [],
    status: 'idle',
}

export const getCompanyPosition = createAsyncThunk('/position', async (_, thunkApi) => {
    try {
        const { data: result } = await getCompanyPositions()
        return result.data
    } catch (error: any) {
        const message = error.message
        return thunkApi.rejectWithValue(message)
    }
})

const positionSlice = createSlice({
    name: 'Position',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyPosition.pending, (state) => {
            state.status = 'loading'
            state.error = null
        })
        builder.addCase(getCompanyPosition.fulfilled, (state, { payload }) => {
            state.positions = []
            state.positions.push(...payload.companyPositions)
            state.status = 'idle'
        })
        builder.addCase(getCompanyPosition.rejected, (state, { payload }) => {
            if (payload) {
                // state.error = payload.message;
                console.log(state.error)
            }
            state.status = 'idle'
        })
    },
})

export default positionSlice.reducer