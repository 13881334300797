import { KTSVG } from "../../../../../_metronic/helpers"

type Props = {
  className?: string
  description: string
  icon: string
  stats: number
  labelColor: string
  textColor: string
}
// style={{width:'320px'}}
const CardsData = ({className, description, stats, icon, labelColor, textColor}: Props) => (
  <div className={`${className} `}>
    <div className={`card  py-3 `}>
      <div className='card-body d-flex flex-row justify-content-between align-items-center'>
        <KTSVG path={icon} className='svg-icon svg-icon-3hx fs-3hx svg-icon-gray-600' />
        <div>
          <div className='card-title d-flex flex-column text-end'>
            <span className='fs-3hx fw-bold text-dark me-2 lh-1 ls-n2'>{stats}</span>
            <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export {CardsData}
