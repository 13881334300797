import {FC, useEffect, useState} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Elements, useStripe} from '@stripe/react-stripe-js'
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ''
const stripePromise = loadStripe(STRIPE_KEY)

const PaymentStatus: FC = () => {
  const [message, setMessage] = useState('')
  const [searchParams] = useSearchParams()
  const payment_intent_client_secret = searchParams.get('payment_intent_client_secret')
  const stripe: any = useStripe()

  useEffect(() => {
    if (!stripe) {
      return
    }

    stripe.retrievePaymentIntent(payment_intent_client_secret).then((result: any) => {
      switch (result.paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe,payment_intent_client_secret])
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>{message}</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        {message === 'Payment succeeded!' && 'Thank you for choosing EZ-complaince'}
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-14'>
        <img
          src={toAbsoluteUrl(
            message === 'Payment succeeded!'
              ? '/media/illustrations/sigma-1/17.png'
              : 'public/media/illustrations/sigma-1/20.png'
          )}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl(
            message === 'Payment succeeded!'
              ? '/media/illustrations/sigma-1/17-dark.png'
              : 'public/media/illustrations/sigma-1/21-dark.png'
          )}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to='/auth' className='btn btn-sm btn-primary'>
          Return Login
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

const Payment: FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentStatus />
    </Elements>
  )
}

export {Payment}
