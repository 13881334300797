/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {accessPermissions} = useAuth()
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {accessPermissions('distributionLogsManagement') && (
        <SidebarMenuItem
          to='/distributionLogs'
          icon='/media/icons/duotune/general/gen014.svg'
          title='Distribution Logs'
          fontIcon='bi-layers'
        />
      )}
      {accessPermissions('registerManagement') && (
        <SidebarMenuItem
          to='/register'
          icon='/media/icons/duotune/communication/com005.svg'
          title='Registers'
          fontIcon='bi-layers'
        />
      )}
      <SidebarMenuItem
          to='/customer'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Customer'
          fontIcon='bi-layers'
        />
      {accessPermissions('employeeManagement') && (
        <SidebarMenuItemWithSub
          to='/employeeCenter'
          title='Employee Centre'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/employeeCenter/employee' title='Employees' hasBullet={true} />
          <SidebarMenuItem to='/employeeCenter/roles' title='Roles' hasBullet={true} />
          <SidebarMenuItem to='/employeeCenter/groups' title='Groups' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}
      {accessPermissions('companyManagement') && (
        <SidebarMenuItemWithSub
          to='/companyInfo'
          title='Company Info'
          icon='/media/icons/duotune/files/fil011.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/companyInfo' title='Company Info' hasBullet={true} />
          {/* <SidebarMenuItem to='/companyInfo' title='Department' hasBullet={true} />
          <SidebarMenuItem to='/companyInfo' title='Position' hasBullet={true} /> */}

        </SidebarMenuItemWithSub>
      )}
      {(accessPermissions('formSettingManagement') || accessPermissions('formsManagement')) && (
        <SidebarMenuItemWithSub
          to='/form'
          title='Forms'
          icon='/media/icons/duotune/communication/com005.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/form/new-form' title='New Form Request' hasBullet={true} />
          <SidebarMenuItem to='/form/list' title='Form List' hasBullet={true} />
          <SidebarMenuItem to='/form/submitted-forms' title='Submitted Forms' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}

      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Form Settings'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/stateRelation' title='State Relation' hasBullet={true} />
        <SidebarMenuItem to='/notifiableAccedent' title='Notifiable Accident' hasBullet={true} />
        <SidebarMenuItem to='/hazardTreatment' title='Hazard Treatment' hasBullet={true} />
        <SidebarMenuItem to='/siteInspection' title='Site Inspection' hasBullet={true} />
        <SidebarMenuItem to='/dynamicForms' title='Dynamic Forms' hasBullet={true} />
        <SidebarMenuItem
          to='/generalFormsSettings'
          title='General Forms Settings'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export {SidebarMenuMain}
