import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { axiosInstance, AxiosResponse } from '../app/modules/auth'

export type RelatedCheckboxes = {
  _id: string
  status: string
  name: string
}
export type RelatedCheckbox = {
  _id: string,
  categoryName: string,
  status: string
  name: string
}
export interface Checkbox {
  _id: string
  relatedCheckboxes: RelatedCheckbox[]
  status: string
  name: string
  identityHazard?: string
  controlAndAction: string
}
export type JobTask = {
  _id: string
  categoryName: string
  status: string
  checkboxes: Checkbox[]
}
type JobTaskState = {
  status: 'loading' | 'idle'
  error: string | null
  jobTask: JobTask[]
}
const initialState: JobTaskState = {
  error: null,
  jobTask: [],
  status: 'idle',
}

export const getJobTaskCategories = createAsyncThunk('/jobTaskCheckboxes', async (_, thunkApi) => {
  try {
    const { data: result } = await axiosInstance.get<AxiosResponse>(
      `formSetting/riskAssessment/getJobTaskCategories`
    )
    return result.data
  } catch (error: any) {
    const message = error.message
    return thunkApi.rejectWithValue(message)
  }
})
const jobTaskSlice = createSlice({
  name: 'jobTaskSlice',
  initialState,
  reducers: {
    addRelationsOption: (state, action) => {

    },
    addCategory: (state, action: PayloadAction<JobTask>) => {
      const index = state.jobTask.findIndex(
        (category) => category.categoryName === action.payload.categoryName
      )
      if (index === -1) {
        state.jobTask.unshift(action.payload)
        return
      }
    },

    updateCategory: (state, action: PayloadAction<JobTask>) => {
      const categindex = state.jobTask.findIndex((category)=>category._id === action.payload._id)
      state.jobTask[categindex].status = action.payload.status
      state.jobTask[categindex].categoryName = action.payload.categoryName
    
    },

    deleteCategory: (state, action: PayloadAction<string>) => {
      state.jobTask.splice(
        state.jobTask.findIndex((category) => category._id === action.payload),
        1
      )
    },
    addOptionInCategory: (state, action: PayloadAction<{ categoryId: string, JobTask: Checkbox }>) => {
      const categIndex =  state.jobTask.findIndex((category)=>category._id === action.payload.categoryId)
      state.jobTask[categIndex].checkboxes.unshift(action.payload.JobTask)
    },
    deleteOptionInCategory: (state, action: PayloadAction<{ categoryId: string, optionId: string }>) => {
      const categIndex =  state.jobTask.findIndex((category)=>category._id === action.payload.categoryId)
      const optionIndex =  state.jobTask[categIndex].checkboxes.findIndex((option) => option._id === action.payload.optionId)
      state.jobTask[categIndex].checkboxes.splice(optionIndex,1)
    },
    updateOptionInCategory: (state, action: PayloadAction<{ categoryId: string, JobTask: Checkbox }>) => {
      const categIndex =  state.jobTask.findIndex((category)=>category._id === action.payload.categoryId)
      state.jobTask[categIndex].checkboxes.map((checkbox, index) => {
        if (checkbox._id === action.payload.JobTask._id) {
          state.jobTask[categIndex].checkboxes[index].name = action.payload.JobTask.name
          state.jobTask[categIndex].checkboxes[index].status = action.payload.JobTask.status
        }
        return checkbox
      })
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getJobTaskCategories.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(getJobTaskCategories.fulfilled, (state, { payload }) => {
      state.jobTask = []
      state.jobTask.push(...payload)
      state.status = 'idle'
    })
    builder.addCase(getJobTaskCategories.rejected, (state, { payload }) => {
      if (payload) {
        // state.error = payload.message;
        console.log(state.error)
      }
      state.status = 'idle'
    })
  },
})
export const { addRelationsOption, addCategory, updateCategory, deleteCategory, addOptionInCategory, deleteOptionInCategory, updateOptionInCategory } = jobTaskSlice.actions
export default jobTaskSlice.reducer
