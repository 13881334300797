import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getJobNumbersList } from '../app/modules/customer/core/_request'

type JobNumbersState = {
    status: 'loading' | 'idle'
    error: string | null
    jobNumbers: {
        _id: string
        jobNumber: string
        customer: {
            contacts: {
                email: string
                phone: string
            }
            address: {
                city: string
                state: string
                streetName: string
            }
            name: string
            ABN: string
        }
        site: {
            siteName: string
            address: {
                streetNumber: string
                streetName: string
                city: string
                state: string
            }
        }
    }[]
}
const initialState: JobNumbersState = {
    error: null,
    jobNumbers: [],
    status: 'idle',
}

export const getJobNumbers = createAsyncThunk('/jobNumber', async (_, thunkApi) => {
    try {
        const { data: result } = await getJobNumbersList()
        return result.data
    } catch (error: any) {
        const message = error.message
        return thunkApi.rejectWithValue(message)
    }
})

const jobNumberSlice = createSlice({
    name: 'JobNumber',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getJobNumbers.pending, (state) => {
            state.status = 'loading'
            state.error = null
        })
        builder.addCase(getJobNumbers.fulfilled, (state, { payload }) => {
            state.jobNumbers = []

            state.jobNumbers.push(...payload)
            state.status = 'idle'
        })
        builder.addCase(getJobNumbers.rejected, (state, { payload }) => {
            if (payload) {
                // state.error = payload.message;
                console.log(state.error)
            }
            state.status = 'idle'
        })
    },
})

export default jobNumberSlice.reducer
