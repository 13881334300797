import * as Yup from 'yup'

export interface CompanyData {
    companyName: string,
    accountType: string,
    teamSize: string,
    companyIndustry: {
        option: string
        other: string
    }
    companyDepartments: string,
    companyPositions?: string,
    companyPhone: string,
    companyFax: string,
    companyEmail: string,
    companyDescription: string,
    ABN?: string,
    ACN?: string,
    companyAddress?: {
        streetNumber: String,
        streetName: String,
        Suburb: String,
        state: String,
        postcode: String,
    },
    website?: string,
    companyLogo?: string,
    businessLicenses?: string,
    branches?: [{
        name: string,
        phone: string,
        department: string,
        teamSize: string,
        licenseNumber: string,
        address: string,
    }],
    socialMediaProfiles?: [{
        platform: string,
        link: string,
    }],

}
export const validationSchema = Yup.object().shape({
    accountType: Yup.string().required().label('Account Type'),
    companyName: Yup.string().required().label('Company Name'),
    teamSize: Yup.string().required().label('Company Size'),
    companyIndustry: Yup.object({
        option: Yup.string().required('Company industry is required'),
        other: Yup.string()
        .when(['option'], (option, schema) => {
            console.log(option, 'option');

            if (option === 'Other') {
                return schema.required('Company industry is required')
            }
            return schema.optional()
        }),
    }),
    companyDepartments: Yup.string().required().label('Company Department'),
    companyEmail: Yup.string().required().label('Company Email'),
    companyPhone: Yup.string().required().label('Company Phone'),
    companyDescription: Yup.string().required().label('Company Description'),
})
export const initailValueCompanyData = {

    accountType: "personal",
    teamSize: "50+",
    companyName: "test Company",
    companyIndustry: {
        option: '',
        other: ''
    },
    companyDepartments: "test Department",
    companyEmail: "momenalhendawy@gmail.com",
    companyPhone: "01095451290",
    companyFax: "555-123-4567",
    companyDescription: "test Discription",

}

export const industryOptions = [
    { label: 'Aged care industry', value: 'Aged care industry' },
    { label: 'Agricultural industry', value: 'Agricultural industry' },
    { label: 'Airline operations', value: 'Airline operations' },
    { label: 'Airport operations', value: 'Airport operations' },
    { label: 'Aluminium industry', value: 'Aluminium industry' },
    { label: 'Ambulance and patient transport', value: 'Ambulance and patient transport' },
    {
        label: 'Amusement, events and recreation industry',
        value: 'Amusement, events and recreation industry',
    },
    { label: 'Animal care and veterinary services', value: 'Animal care and veterinary services' },
    { label: 'Aquaculture', value: 'Aquaculture' },
    { label: 'Asphalt industry', value: 'Asphalt industry' },
    { label: 'Australian Capital Territory', value: 'Australian Capital Territory' },
    {
        label: 'Banking, finance and insurance industry',
        value: 'Banking, finance and insurance industry',
    },
    {
        label: 'Broadcasting and recorded entertainment industry',
        value: 'Broadcasting and recorded entertainment industry',
    },
    { label: 'Building services', value: 'Building services' },
    {
        label: 'Building, metal and civil construction industries',
        value: 'Building, metal and civil construction industries',
    },
    { label: 'Business equipment industry', value: 'Business equipment industry' },
    { label: 'Cement and concrete products', value: 'Cement and concrete products' },
    { label: 'Cemetery operations', value: 'Cemetery operations' },
    { label: "Children's services", value: "Children's services" },
    { label: 'Christmas Island', value: 'Christmas Island' },
    { label: 'Cleaning services', value: 'Cleaning services' },
    { label: 'Clerical industry', value: 'Clerical industry' },
    { label: 'Clothing industry', value: 'Clothing industry' },
    { label: 'Coal export terminals', value: 'Coal export terminals' },
    { label: 'Coal industry', value: 'Coal industry' },
    { label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
    { label: 'Commercial sales', value: 'Commercial sales' },
    { label: 'Commonwealth employment', value: 'Commonwealth employment' },
    { label: 'Contract call centre industry', value: 'Contract call centre industry' },
    { label: 'Corrections and detentions', value: 'Corrections and detentions' },
    { label: 'Diving services', value: 'Diving services' },
    { label: 'Dredging industry', value: 'Dredging industry' },
    { label: 'Dry cleaning and laundry services', value: 'Dry cleaning and laundry services' },
    { label: 'Educational services', value: 'Educational services' },
    { label: 'Electrical contracting industry', value: 'Electrical contracting industry' },
    { label: 'Electrical power industry', value: 'Electrical power industry' },
    { label: 'Fast food industry', value: 'Fast food industry' },
    { label: 'Federal police operations', value: 'Federal police operations' },
    { label: 'Fire fighting services', value: 'Fire fighting services' },
    {
        label: 'Food, beverages and tobacco manufacturing industry',
        value: 'Food, beverages and tobacco manufacturing industry',
    },
    { label: 'Funeral directing services', value: 'Funeral directing services' },
    { label: 'Gardening services', value: 'Gardening services' },
    { label: 'Grain handling industry', value: 'Grain handling industry' },
    { label: 'Graphic arts', value: 'Graphic arts' },
    { label: 'Hair and beauty', value: 'Hair and beauty' },
    { label: 'Health and welfare services', value: 'Health and welfare services' },
    { label: 'Hospitality industry', value: 'Hospitality industry' },
    {
        label: 'Indigenous organisations and services',
        value: 'Indigenous organisations and services',
    },
    { label: 'Journalism', value: 'Journalism' },
    { label: 'Licensed and registered clubs', value: 'Licensed and registered clubs' },
    { label: 'Live performance industry', value: 'Live performance industry' },
    { label: 'Local government administration', value: 'Local government administration' },
    { label: 'Mannequins and modelling', value: 'Mannequins and modelling' },
    {
        label: 'Manufacturing and associated industries',
        value: 'Manufacturing and associated industries',
    },
    { label: 'Marine tourism and charter vessels', value: 'Marine tourism and charter vessels' },
    {
        label: 'Market and business consultancy services',
        value: 'Market and business consultancy services',
    },
    { label: 'Meat industry', value: 'Meat industry' },
    { label: 'Mining industry', value: 'Mining industry' },
    { label: 'Miscellaneous', value: 'Miscellaneous' },
    { label: 'Norfolk Island', value: 'Norfolk Island' },
    { label: 'Northern Territory', value: 'Northern Territory' },
    { label: 'Nursery industry', value: 'Nursery industry' },
    {
        label: 'Passenger vehicle transport (non rail) industry',
        value: 'Passenger vehicle transport (non rail) industry',
    },
    { label: 'Pet food manufacturing', value: 'Pet food manufacturing' },
    { label: 'Pharmaceutical industry', value: 'Pharmaceutical industry' },
    { label: 'Pharmacy operations', value: 'Pharmacy operations' },
    { label: 'Plumbing industry', value: 'Plumbing industry' },
    { label: 'Port authorities', value: 'Port authorities' },
    { label: 'Postal services', value: 'Postal services' },
    { label: 'Poultry processing', value: 'Poultry processing' },
    { label: 'Publishing industry', value: 'Publishing industry' },
    { label: 'Quarrying industry', value: 'Quarrying industry' },
    { label: 'Racing industry', value: 'Racing industry' },
    { label: 'Rail industry', value: 'Rail industry' },
    { label: 'Real estate industry', value: 'Real estate industry' },
    { label: 'Restaurants', value: 'Restaurants' },
    { label: 'Retail industry', value: 'Retail industry' },
    { label: 'Road transport industry', value: 'Road transport industry' },
    {
        label: 'Rubber, plastic and cable making industry',
        value: 'Rubber, plastic and cable making industry',
    },
    { label: 'Salt industry', value: 'Salt industry' },
    { label: 'Scientific services', value: 'Scientific services' },
    { label: 'Seafood processing', value: 'Seafood processing' },
    { label: 'Security services', value: 'Security services' },
    {
        label: 'Social, community, home care and disability services',
        value: 'Social, community, home care and disability services',
    },
    { label: 'Sporting organisations', value: 'Sporting organisations' },
    {
        label: 'State and Territory government administration',
        value: 'State and Territory government administration',
    },
    { label: 'Stevedoring industry', value: 'Stevedoring industry' },
    { label: 'Storage services', value: 'Storage services' },
    { label: 'Sugar industry', value: 'Sugar industry' },
    { label: 'Tasmania', value: 'Tasmania' },
    { label: 'Technical services', value: 'Technical services' },
    { label: 'Telecommunications services', value: 'Telecommunications services' },
    { label: 'Textile industry', value: 'Textile industry' },
    { label: 'Timber and paper products industry', value: 'Timber and paper products industry' },
    { label: 'Tourism industry', value: 'Tourism industry' },
    { label: 'Uranium mining (incl. construction)', value: 'Uranium mining (incl. construction)' },
    { label: 'Vehicle industry', value: 'Vehicle industry' },
    { label: 'Waste management industry', value: 'Waste management industry' },
    {
        label: 'Water, sewerage and drainage services',
        value: 'Water, sewerage and drainage services',
    },
    { label: 'Wine industry', value: 'Wine industry' },
    {
        label: 'Wool storage, sampling and testing industry',
        value: 'Wool storage, sampling and testing industry',
    },
    { label: 'Other', value: 'Other' },
]