import { axiosInstance, AxiosResponse } from "../../auth"

export const addCustomer = (formData: any) => {
    return axiosInstance.post<AxiosResponse>(`customer/add`, formData)
}
export const getCustomers = (customerId: string, page: number, limit: number) => {
    return axiosInstance.get<AxiosResponse>(`customer/getAll?page=${page}&limit=${limit}&searchByCustomerId=${customerId}`)
}
export const updateCustomer = (customerId: string, formData: any) => {
    return axiosInstance.put<AxiosResponse>(`customer/update/${customerId}`, formData)
}
export const deleteCustomer = (customerId: string) => {
    return axiosInstance.delete<AxiosResponse>(`customer/delete/${customerId}`)
}

//************************* Site ************************ */

export const getSites = (customerId: string, siteId: string, page: number, limit: number) => {
    return axiosInstance.get<AxiosResponse>(`site/getAll/${customerId}?page=${page}&limit=${limit}&searchBySiteId=${siteId}`)
}
export const addSite = (formData: any) => {
    return axiosInstance.post<AxiosResponse>(`site/add`, formData)
}
export const updateSite = (siteId: string, formData: any) => {
    return axiosInstance.put<AxiosResponse>(`site/update/${siteId}`, formData)
}
export const deleteSite = (siteId: string) => {
    return axiosInstance.delete<AxiosResponse>(`site/delete/${siteId}`)
}

//************************* Job Number ************************/

export const getJobNumbersInSite = (siteId: string, jobNumberId: string, page: number, limit: number) => {
    return axiosInstance.get<AxiosResponse>(`jobNumber/getAllInSite/${siteId}?searchByJobNumberId=${jobNumberId}&page=${page}&limit=${limit}`)
}
export const getJobNumbersList = () => {
    return axiosInstance.get<AxiosResponse>(`jobNumber/getAll`)
}
export const addJobNumber = (formData: any) => {
    return axiosInstance.post<AxiosResponse>(`jobNumber/add`, formData)
}
export const updateJobNumber = (jobNumberId: string, formData: any) => {
    return axiosInstance.put<AxiosResponse>(`jobNumber/update/${jobNumberId}`, formData)
}
export const deleteJobNumber = (jobNumberId: string) => {
    return axiosInstance.delete<AxiosResponse>(`jobNumber/delete/${jobNumberId}`)
}