import { combineReducers } from '@reduxjs/toolkit'
import riskAssesmmentReducer from './riskAssessmentCheckBoxes'
import jobTaskReducer from './jobTaskCheckBoxex'
import jobNumberReducer from './jobNumber'
import departmentReducer from './department'
import positionReducer from './position'
export const rootReducer = combineReducers({
    riskAssesmment: riskAssesmmentReducer,
    jobTask: jobTaskReducer,
    jobNumber: jobNumberReducer,
    department: departmentReducer,
    position: positionReducer
})