/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {PendingIssue} from '../../../_metronic/partials/widgets/charts/PendingIssue'
import {TypesOfInjuries} from '../../../_metronic/partials/widgets/charts/TypesOfInjuries'
import { CorrectiveActions } from '../../../_metronic/partials/widgets/tables/CorrectiveActions'
import { CardsData } from './component/cards/CardsData'

const cardsData: {
  nearMissCount: number
  firstAidCount: number
  medicalTreatmentCount: number
  lostTimeInjuryCount: number
  deathDataCount: number
  hazardResult: number
  incidentFormResult: number
  riskAssessmentResult: number
  toolBoxResult: number
  siteInspectionResult: number
  savedDynamicFormResult: number
} = {
  nearMissCount: 15,
  firstAidCount: 10,
  medicalTreatmentCount: 20,
  lostTimeInjuryCount: 15,
  deathDataCount: 17,
  hazardResult: 13,
  incidentFormResult: 20,
  riskAssessmentResult: 18,
  toolBoxResult: 22,
  siteInspectionResult: 19,
  savedDynamicFormResult: 22,
}

const tableData = [
  {
    item: 'Wrong placement of equipment',
    action: '7 min ago',
    personResponible: 'James Web',
    reportTo: 'Michael Koly',
    dueDate: '26 June 2022',
    status: false,
  },
  {
    item: 'Missing tools',
    action: '52 min ago',
    personResponible: 'David Harry',
    reportTo: 'Sadio Manny',
    dueDate: '26 June 2022',
    status: true,
  },
  {
    item: 'Equipment are broken',
    action: '1 hour ago',
    personResponible: 'Emad Adam',
    reportTo: 'Samy Farid',
    dueDate: '26 June 2022',
    status: true,
  },
  {
    item: 'Broken ladder',
    action: '3 hour ago',
    personResponible: 'Ramy Farid',
    reportTo: 'James Kalo',
    dueDate: '26 June 2022',
    status: false,
  },
  {
    item: 'Outdated machine system',
    action: '2 day ago',
    personResponible: 'Jimmy Albert',
    reportTo: 'Lory Lamye',
    dueDate: '26 June 2022',
    status: false,
  },
  {
    item: 'Missing tools machine system',
    action: '52 min ago',
    personResponible: 'David Harry',
    reportTo: 'Sadio Manny',
    dueDate: '26 June 2022',
    status: true,
  },
]

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row justify-content-around mb-6'>
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='Near Miss'
        icon='/media/icons/duotune/maps/map004.svg'
        stats={cardsData.nearMissCount}
        labelColor='dark'
        textColor='gray-300'
      />
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='first Aid'
        icon='/media/icons/duotune/graphs/gra001.svg'
        stats={cardsData.firstAidCount}
        labelColor='dark'
        textColor='gray-300'
      />
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='medical Treatment'
        icon='/media/icons/duotune/abstract/abs048.svg'
        stats={cardsData.incidentFormResult}
        labelColor='dark'
        textColor='gray-300'
      />
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='lost Time Injury'
        icon='/media/icons/duotune/maps/map002.svg'
        stats={cardsData.lostTimeInjuryCount}
        labelColor='dark'
        textColor='gray-300'
      />
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='death Data'
        icon='/media/icons/duotune/maps/map002.svg'
        stats={cardsData.deathDataCount}
        labelColor='dark'
        textColor='gray-300'
      />
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='hazard Result'
        icon='/media/icons/duotune/maps/map002.svg'
        stats={cardsData.hazardResult}
        labelColor='dark'
        textColor='gray-300'
      />
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='tool Box Result'
        icon='/media/icons/duotune/maps/map002.svg'
        stats={cardsData.toolBoxResult}
        labelColor='dark'
        textColor='gray-300'
      />
      <CardsData
        className='my-3 col-md-5 col-lg-5 col-xl-3 col-xxl-3 '
        description='SWMS'
        icon='/media/icons/duotune/maps/map002.svg'
        stats={cardsData.savedDynamicFormResult}
        labelColor='dark'
        textColor='gray-300'
      />
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row'>
      <div className='col-xxl-12'>
        <CorrectiveActions className='card-xxl-stretch mb-5 mb-xxl-8' tableLable="Corrective Actions" tableData={tableData} />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        <PendingIssue className='card card-flush h-lg-100' />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        <TypesOfInjuries className='card card-flush h-lg-100' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
