import Select from 'react-select'

const ReactSelect = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder,
  styles,
  className
}: any) => {
  function onChange(option: any) {
    form.setFieldValue(field.name, option ? option.map((item: any) => item.value) : [])
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option: any) => field.value.indexOf(option.value) >= 0)
        : options.find((option: any) => option.value === field.value)
    } else {
      return isMulti ? [] : ''
    }
  }

  if (!isMulti) {
    return (
      <Select
        options={options}
        name={field.name}
        value={options ? options.find((option: any) => option.value === field.value) : ''}
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        placeholder={placeholder}
        classNamePrefix='react-select'
        styles={styles}
        // className={className}
      />
    )
  } else {
    return (
      <Select
        classNamePrefix='react-select'
        name={field.name}
        value={getValue()}
        onChange={onChange}
        options={options}
        isMulti={true}
        placeholder={placeholder}
        styles={styles}
      />
    )
  }
}

export default ReactSelect
