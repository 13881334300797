import {ErrorMessage, Field, useField} from 'formik'
import {FC, useCallback, useEffect} from 'react'
import {validOwnerAccount} from '../../core/_requests'
type props = {
  setStatus: (error: any) => void
  status: any
}
const ContactDetails: FC<props> = ({setStatus, status}) => {
  const [, metaPersonEmail, ] = useField('personEmail')
  const [, metaPersonPhone, ] = useField('personPhone')

  const validOwner = useCallback(async () => {
    try {
      if (metaPersonEmail.value || metaPersonPhone.value) {
        await validOwnerAccount(metaPersonEmail.value, metaPersonPhone.value)
        setStatus({})
      }
    } catch (error: any) {
      if (metaPersonEmail.value) {
        setStatus({personEmail: error.response.data.message})
      }
      if (metaPersonPhone.value) {
        setStatus({personPhone: error.response.data.message})
      }
    }
  }, [metaPersonEmail.value, metaPersonPhone.value,setStatus])

  useEffect(() => {
    const timer = setTimeout(() => {
      validOwner()
    }, 700)
    return () => clearTimeout(timer)
  }, [validOwner])

  return (
    <div className='container mx-auto w-75'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Contact Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>
      <div className='row fv-row mb-10'>
        <div className='col-6'>
          <label className='fs-6 fw-bold form-label required'>First Name</label>
          <Field
            name='personFirstName'
            className='form-control form-control-lg form-control-solid'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='personFirstName' />
          </div>
        </div>
        <div className='col-6'>
          <label className='fs-6 fw-bold form-label required'>Last Name</label>
          <Field
            name='personLastName'
            className='form-control form-control-lg form-control-solid'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='personLastName' />
          </div>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label required'>Person Email</label>
        <Field name='personEmail' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='personEmail'>
            {(msg) => <div>{msg || status?.personEmail}</div>}
          </ErrorMessage>
          {status?.personEmail}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label required'>Person Phone</label>
        <Field name='personPhone' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='personPhone'>
            {(msg) => <div>{msg || status?.personPhone}</div>}
          </ErrorMessage>
          {status?.personPhone}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label required'>Password</label>
        <Field name='password' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='password' />
        </div>
      </div>
    </div>
  )
}

export {ContactDetails}
