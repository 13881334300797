import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAppDispatch } from '../../hooks/useTypedSelector'
import { getJobNumbers } from '../../redux/jobNumber'
import { getCompanyDepartments } from '../../redux/department'
import { getCompanyPosition } from '../../redux/position'
import { getOptionsByCategory } from '../../redux/riskAssessmentCheckBoxes'
import { getJobTaskCategories } from '../../redux/jobTaskCheckBoxex'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const PaymentStatusPage = lazy(() => import('../modules/paymentStatus/PaymentStatusPage'))
  const FormSetting = lazy(() => import('../modules/formSetting/FormSetting'))
  const FormPage = lazy(() => import('../modules/forms/FormPage'))
  const RegisterPage = lazy(() => import('../modules/registers/RegisterPage'))
  const EmployeeCenterPage = lazy(() => import('../modules/employeeCenter/EmployeeCenterPage'))
  const CompanyInfoPage = lazy(() => import('../modules/companyInfo/CompanyInfoPage'))
  const CustomerPage = lazy(() => import('../modules/customer/customerPage'))
  const DistributionLogsPage = lazy(
    () => import('../modules/distributionLogs/DistributionLogsPage')
  )

  const dispatch = useAppDispatch()
  useEffect(()=>{
    dispatch(getJobNumbers())
    dispatch(getCompanyDepartments())
    dispatch(getCompanyPosition())
    dispatch(getOptionsByCategory("ALL"))
    dispatch(getJobTaskCategories())
  },[dispatch])

 
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='form/*'
          element={
            <SuspensedView>
              <FormPage />
            </SuspensedView>
          }
        />
        <Route
          path='form-setting/*'
          element={
            <SuspensedView>
              <FormSetting />
            </SuspensedView>
          }
        />
        <Route
          path='register/*'
          element={
            <SuspensedView>
              <RegisterPage />
            </SuspensedView>
          }
        />
        <Route
          path='employeeCenter/*'
          element={
            <SuspensedView>
              <EmployeeCenterPage />
            </SuspensedView>
          }
        />

        <Route
          path='companyInfo/*'
          element={
            <SuspensedView>
              <CompanyInfoPage />
            </SuspensedView>
          }
        />
        <Route
          path='distributionLogs/*'
          element={
            <SuspensedView>
              <DistributionLogsPage />
            </SuspensedView>
          }
        />
        <Route
          path='customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='payment/*'
          element={
            <SuspensedView>
              <PaymentStatusPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
