import { CreateAccount } from '../../wizards/components/CreateAccountWizardHelper'
import { axiosInstance, AxiosResponse } from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL

export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axiosInstance.post<{ data: any, status: string }>('/authentication/login', {
    email,
    password,
  })
}

// Server should return AuthModel
export function validOwnerAccount(email: string, mobileNumber: string) {
  return axiosInstance.get<AxiosResponse>(`/authentication/validOwner?email=${email}&mobileNumber=${mobileNumber}`)
}

// Server should return AuthModel
export function validCompanyAccount(companyABN: string) {
  return axiosInstance.get<AxiosResponse>(`/authentication/validCompany?companyABN=${companyABN}`)
}

// Server should return AuthModel
export function register(acount: CreateAccount) {
  return axiosInstance.post('/stripe/signUp', {
    ...acount
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axiosInstance.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axiosInstance.get<AxiosResponse>(`/authentication/getProfile`, {})
}
