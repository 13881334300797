import {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step3: FC = () => {
  return (
    <div className='container mx-auto w-75'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Business Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label required'>Company Email</label>
        <Field name='companyEmail' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyEmail' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label required'>Company Phone</label>
        <Field name='companyPhone' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyPhone' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Company Fax</label>
        <Field name='companyFax' className='form-control form-control-lg form-control-solid' />
      </div>
      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label required'>Company Description</label>

        <Field
          as='textarea'
          name='companyDescription'
          className='form-control form-control-lg form-control-solid'
          rows={3}
        ></Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyDescription' />
        </div>
      </div>
    </div>
  )
}

export {Step3}
