import {Field} from 'formik'
import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

const Step4: FC = () => {
  return (
    <div className='w-100 d-flex justify-content-center row g-10'>
      <div className='col-xl-4'>
        <Field
          type='radio'
          className='btn-check'
          name='companyPlan'
          value='Essentials'
          id='kt_create_account_form_account_type_Price_Essentials'
        />
        <label
          className='btn p-3 btn-outline btn-outline-dashed btn-outline-default d-flex h-100'
          htmlFor='kt_create_account_form_account_type_Price_Essentials'
          style={{textAlign:'left'}}
        >
          <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 p-10'>
            <div className='mb-7 text-center'>
              <h1 className='text-dark mb-5 fw-bolder'>Essentials</h1>

              <div className='text-gray-400 fw-semibold mb-5'>
                Optimal for 9 User
                <br /> Up to 3 Users
              </div>

              <div className='text-center'>
                <span className='mb-2 text-primary'>$</span>

                <span
                  className='fs-3x fw-bold text-primary'
                  data-kt-plan-price-month='999'
                  data-kt-plan-price-annual='9999'
                >
                  39{' '}
                </span>

                <span className='fs-7 fw-semibold opacity-50'>
                  /<span data-kt-element='period'>Mon</span>
                </span>
              </div>
            </div>

            <div className='w-100 mb-10'>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Risk Assessment Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                  SWMS Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 '>
                  Automated Site Inspection
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Automated Corrective Actions
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Forms Registry
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Form Version tracking
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  New Form Requests
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Company Branding
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Monitoring Dashboard
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Plant Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Document Register
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Approval request
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Employee Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Future Releases
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
            </div>
          </div>
        </label>
      </div>
      <div className='col-xl-4'>
        <Field
          type='radio'
          className='btn-check'
          name='companyPlan'
          value='Business'
          id='kt_create_account_form_account_type_Price_Business'
        />
        <label
          className='btn p-3 text-left btn-outline btn-outline-dashed btn-outline-default d-flex h-100'
          htmlFor='kt_create_account_form_account_type_Price_Business'
          style={{textAlign:'left'}}
        >
          <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 p-10 '>
            <div className='mb-7 text-center'>
              <h1 className='text-dark mb-5 fw-bolder'>Business</h1>

              <div className='text-gray-400 fw-semibold mb-5'>
                Optimal for 10 User
                <br /> Up to 5 Users
              </div>

              <div className='text-center'>
                <span className='mb-2 text-primary'>$</span>

                <span
                  className='fs-3x fw-bold text-primary'
                  data-kt-plan-price-month='39'
                  data-kt-plan-price-annual='399'
                >
                  55{' '}
                </span>

                <span className='fs-7 fw-semibold opacity-50'>
                  /<span data-kt-element='period'>Mon</span>
                </span>
              </div>
            </div>

            <div className='w-100 mb-10'>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Risk Assessment Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                  SWMS Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 '>
                  Automated Site Inspection
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Automated Corrective Actions
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Forms Registry
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Form Version tracking
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  New Form Requests
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Company Branding
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Monitoring Dashboard
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Plant Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Document Register
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Approval request
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Employee Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Future Releases
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
            </div>
          </div>
        </label>
      </div>
      <div className='col-xl-4'>
        <Field
          type='radio'
          className='btn-check'
          name='companyPlan'
          value='Growth'
          id='kt_create_account_form_account_type_Price_Growth'
        />
        <label
          className='btn p-3 text-left btn-outline btn-outline-dashed btn-outline-default d-flex h-100'
          htmlFor='kt_create_account_form_account_type_Price_Growth'
          style={{textAlign:'left'}}
        >
          <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 p-10'>
            <div className='mb-7 text-center'>
              <h1 className='text-dark mb-5 fw-bolder'>Growth</h1>

              <div className='text-gray-400 fw-semibold'>
                Optimal for 15 User <br />
                Up to 10 Users ($8 per every additional user)
              </div>

              <div className='text-center'>
                <span className='mb-2 text-primary'>$</span>

                <span
                  className='fs-3x fw-bold text-primary'
                  data-kt-plan-price-month='39'
                  data-kt-plan-price-annual='399'
                >
                  90{' '}
                </span>

                <span className='fs-7 fw-semibold opacity-50'>
                  /<span data-kt-element='period'>Mon</span>
                </span>
              </div>
            </div>

            <div className='w-100 mb-10'>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Risk Assessment Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                  SWMS Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 '>
                  Automated Site Inspection
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Automated Corrective Actions
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Forms Registry
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Form Version tracking
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  New Form Requests
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Company Branding
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Monitoring Dashboard
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Plant Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Document Register
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Approval request
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Employee Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-500 flex-grow-1 pe-3'>
                  Future Releases
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon svg-icon-1'
                />
              </div>
            </div>
          </div>
        </label>
      </div>
      {/* <div className='col-xl-3'>
        <Field
          type='radio'
          className='btn-check'
          name='plan'
          value='corporate'
          id='kt_create_account_form_account_type_Price_Corporate'
        />
        <label
          className='btn p-3 text-left btn-outline btn-outline-dashed btn-outline-default d-flex h-100'
          htmlFor='kt_create_account_form_account_type_Price_Corporate'
          style={{textAlign:'left'}}
        >
          <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 p-10'>
            <div className='mb-7 text-center'>
              <h1 className='text-dark mb-5 fw-bolder'>Corporate</h1>

              <div className='text-gray-400 fw-semibold mb-5'>
                Optimal for 100+ User
                <br /> and new startup
              </div>

              <div className='text-center'>
                <span
                  className='fs-3x fw-bold text-primary'
                  data-kt-plan-price-month='39'
                  data-kt-plan-price-annual='399'
                >
                  Contact Us
                </span>
              </div>
            </div>

            <div className='w-100 mb-10'>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Risk Assessment Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                  SWMS Automation
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 '>
                  Automated Site Inspection
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Automated Corrective Actions
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Forms Registry
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Form Version tracking
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  New Form Requests
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Company Branding
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Monitoring Dashboard
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Plant Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Document Register
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Approval request
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Employee Centre
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span className='fw-semibold fs-7  text-gray-800 flex-grow-1 pe-3'>
                  Future Releases
                </span>
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
              </div>
            </div>
          </div>
        </label>
      </div> */}
    </div>
  )
}
export {Step4}
