import {FC} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import {StripeForm} from '../../../../../_metronic/layout/components/Stripe/StripeForm'
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ''
const stripePromise = loadStripe(STRIPE_KEY)
type props = {
  message:string
  clientSecret: string
  setStripe: (stripe: any) => void
  setElement: (element: any) => void
}
const Step5: FC<props> = ({clientSecret,setStripe, setElement,message}) => {

  const stripeOptions: {
    clientSecret: string
    appearance: {theme: 'stripe' | 'flat'}
  } = {
    clientSecret,
    appearance: {
      theme: 'flat',
    },
  }

  return (
    <>
      {clientSecret && (
        <Elements options={stripeOptions} stripe={stripePromise}>
          <StripeForm message={message} clientSecret={clientSecret} setStripe={setStripe} setElement={setElement} />
        </Elements>
      )}
    </>
  )
}

export {Step5}
